import classNames from "classnames";
import { Icon } from "components/widgets/Icon";
import { ComponentProps, forwardRef, Fragment, memo, useState } from "react";
import styled from "styled-components";
import { TextField } from "..";

type Props = Omit<ComponentProps<typeof TextField>, "type">;

export const PasswordField = memo(
  forwardRef<HTMLInputElement, Props>(({ className, ...otherProps }, ref) => {
    const [visible, setVisible] = useState(false);

    return (
      <Fragment>
        <Base
          ref={ref}
          {...otherProps}
          block
          className={classNames(className)}
          type={visible ? "text" : "password"}
        />
        <Toggle type="button" onClick={() => setVisible((prev) => !prev)}>
          {visible ? <Icon.Eye /> : <Icon.EyeSlash />}
        </Toggle>
      </Fragment>
    );
  })
);
PasswordField.displayName = "PasswordField";

const Base = styled(TextField)`
  padding-right: 56px;
`;

const Toggle = styled.button`
  background: none;
  border: 0;
  float: right;
  height: 56px;
  margin-top: -56px;
  outline: 0;
  position: relative;
  width: 56px;
  z-index: 1;

  svg {
    height: 24px;
    width: 24px;
  }
`;
