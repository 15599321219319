"use client";

import classNames from "classnames";
import { ComponentProps, ReactNode } from "react";
import styled, { css } from "styled-components";

import { palette } from "libs/styles/colors";
import {
  duration,
  fontFamily,
  fontSize,
  radius,
  spacing,
} from "libs/styles/variables";
import Link from "next/link";

type ButtonTheme =
  | "default"
  | "primary"
  | "secondary"
  | "danger"
  | "white"
  | "black12";
type ButtonSize = "sm" | "md" | "lg";

type ButtonProps = {
  buttonTheme?: ButtonTheme;
  size?: ButtonSize;
  block?: boolean;
  className?: string;
  children: ReactNode;
};

type Props = ComponentProps<typeof Link> & ButtonProps;

export const LinkButton = ({
  block,
  children,
  className,
  size = "md",
  buttonTheme = "default",
  ...otherProps
}: Props) => {
  return (
    <Base
      $block={block}
      className={classNames(className)}
      $size={size}
      $buttonTheme={buttonTheme}
      {...otherProps}
    >
      {children}
    </Base>
  );
};

LinkButton.displayName = "LinkButton";

type BaseProps = {
  $buttonTheme: ButtonTheme;
  $size?: ButtonSize;
  $block?: boolean;
} & Omit<Props, "block" | "size" | "buttonTheme">;

const Base = styled(Link)<BaseProps>`
  align-items: center;
  border: 2px solid transparent;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-family: ${fontFamily.default};
  font-size: ${fontSize.sm};
  font-weight: 500;
  justify-content: center;
  outline: 0;
  padding: 0;
  position: relative;
  text-align: center;
  text-decoration: none;

  &:hover {
    transition: box-shadow ${duration.default};
  }

  &:active {
    transition: none;
  }

  ${({ $block }) =>
    $block &&
    css`
      display: flex;
      width: 100%;
    `}

  ${({ $size }) => getSizeStyles($size)}
  ${({ $buttonTheme }) => getThemeStyles($buttonTheme)}
`;

const getSizeStyles = (size?: ButtonSize) => {
  switch (size) {
    case "sm":
      return css`
        border-radius: ${radius.circle};
        font-size: ${fontSize.xs};
        height: 30px;
        padding: 0 ${spacing.md};

        > svg {
          height: 18px;
          margin-left: -${spacing.xxs};
          margin-right: ${spacing.xs};
          width: 18px;

          &:only-child {
            margin: 0 -${spacing.lg};
          }
        }

        > span + svg {
          margin-left: ${spacing.xs};
          margin-right: -${spacing.xxs};
        }
      `;
    case "md":
      return css`
        border-radius: ${radius.md};
        font-size: ${fontSize.sm};
        height: 48px;
        padding: 0 ${spacing.xxl};

        > svg {
          height: 20px;
          margin-left: -${spacing.md};
          margin-right: ${spacing.sm};
          width: 20px;

          &:only-child {
            margin: 0 -${spacing.lg};
          }
        }

        > span + svg {
          margin-left: ${spacing.sm};
          margin-right: -${spacing.md};
        }
      `;
    case "lg":
      return css`
        border-radius: ${radius.md};
        font-size: ${fontSize.sm};
        height: 56px;
        padding: 0 ${spacing.xxl};

        > svg {
          height: 24px;
          margin-left: -${spacing.sm};
          margin-right: ${spacing.sm};
          width: 24px;

          &:only-child {
            margin: 0 -${spacing.lg};
          }
        }

        > span + svg {
          margin-left: ${spacing.sm};
          margin-right: -${spacing.sm};
        }
      `;
  }
};

const getThemeStyles = (theme: ButtonTheme) => {
  switch (theme) {
    case "primary":
      return css`
        background-color: ${palette.blue.default};
        border-color: ${palette.blue.default};
        color: ${palette.white[100]};

        &:hover {
          background-color: ${palette.blue.dark1};
          border-color: ${palette.blue.dark1};
        }
        &:focus {
          border-color: ${palette.blue.dark1};
        }
        &:active {
          background-color: ${palette.blue.dark2};
          border-color: ${palette.blue.dark2};
        }
      `;
    case "secondary":
      return css`
        background-color: ${palette.turquoise.default};
        border-color: ${palette.turquoise.default};
        color: ${palette.white[100]};

        &:hover {
          background-color: ${palette.turquoise.dark1};
          border-color: ${palette.turquoise.dark1};
        }
        &:focus {
          border-color: ${palette.blue.dark1};
        }
        &:active {
          background-color: ${palette.turquoise.dark2};
          border-color: ${palette.turquoise.dark2};
        }
      `;
    case "danger":
      return css`
        background-color: ${palette.red.default};
        border-color: ${palette.red.default};
        color: ${palette.white[100]};

        &:hover {
          background-color: ${palette.red.dark1};
          border-color: ${palette.red.dark1};
        }
        &:focus {
          border-color: ${palette.red.dark1};
        }
        &:active {
          background-color: ${palette.red.dark2};
          border-color: ${palette.red.dark2};
        }
      `;
    case "white":
      return css`
        background-color: ${palette.white[100]};
        border-color: ${palette.white[100]};
        color: ${palette.black[87]};

        &:hover {
          background-color: ${palette.white[87]};
          border-color: ${palette.white[87]};
        }
        &:focus {
          border-color: ${palette.blue.dark1};
        }
        &:active {
          background-color: ${palette.white[56]};
          border-color: ${palette.white[56]};
        }
      `;
    case "black12":
      return css`
        background-color: transparent;
        border-color: ${palette.black[12]};
        color: ${palette.black[38]};

        &:hover {
          background-color: ${palette.black[12]};
          border-color: ${palette.black[12]};
        }
        &:focus {
          border-color: ${palette.black[12]};
        }
        &:active {
          background-color: ${palette.black[12]};
          border-color: ${palette.black[12]};
        }
      `;
    case "default":
    default:
      return css`
        background-color: ${palette.extra.backgroundColor.control};
        border-color: ${palette.extra.backgroundColor.control};
        color: ${palette.black[87]};

        &:hover {
          border-color: ${palette.black[4]};
        }
        &:focus {
          border-color: ${palette.blue.dark1};
        }
        &:active {
          border-color: ${palette.black[12]};
        }
      `;
  }
};
