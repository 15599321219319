"use client";

import { Fragment, memo } from "react";
import styled from "styled-components";

const Base = styled.div`
  text-align: center;
  background-color: #ffb101;
  color: black;
  font-weight: bold;
  height: 44px;
  line-height: 24px;
  border: 10px solid pink;
  border-image: repeating-linear-gradient(
      -55deg,
      #000,
      #000 20px,
      #ffb101 20px,
      #ffb101 40px
    )
    10;
`;

export const EnvironmentDanger = memo(function EnvironmentDanger() {
  return (
    <Fragment>
      {process.env.NEXT_PUBLIC_APP_ENV == "production" ? null : (
        <Base>{process.env.NEXT_PUBLIC_APP_ENV}</Base>
      )}
    </Fragment>
  );
});
