"use client";

import { spacing } from "libs/styles/variables";
import { ReactNode } from "react";
import styled from "styled-components";

type spacingSize = "sm" | "md" | "lg" | "xl" | "xxl";
type alignItems = "flex-start" | "flex-end" | "center" | "stretch" | "baseline";

type Props = {
  children?: ReactNode;
  spacing?: spacingSize;
  fullWidth?: boolean;
  alignItems?: alignItems;
};

export function FlexStack({
  children,
  spacing = "md",
  fullWidth = false,
  alignItems = "flex-start",
}: Props) {
  return (
    <StackBase
      $spacingSize={spacing}
      $fullWidth={fullWidth}
      $alignItems={alignItems}
    >
      {children}
    </StackBase>
  );
}

const StackBase = styled.div<{
  $spacingSize: spacingSize;
  $fullWidth: boolean;
  $alignItems: alignItems;
}>`
  display: flex;
  flex-direction: column;
  align-items: ${({ $alignItems }) => $alignItems};
  ${({ $spacingSize }) => {
    switch ($spacingSize) {
      case "sm":
        return `gap: ${spacing.sm};`;
      case "md":
        return `gap: ${spacing.md};`;
      case "lg":
        return `gap: ${spacing.lg};`;
      case "xl":
        return `gap: ${spacing.xl};`;
      case "xxl":
        return `gap: ${spacing.xxl};`;
    }
  }}
  ${({ $fullWidth }) => $fullWidth && "width: 100%;"}
`;
