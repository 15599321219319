"use client";

import { Colors } from "components/colors";
import { Font } from "components/font";
import { H1 } from "components/widgets/H";
import { spacing } from "libs/styles/variables";
import { Children, Fragment, memo, ReactNode } from "react";
import styled, { css } from "styled-components";

type spacingSize = "sm" | "md" | "lg" | "xl" | "xxl";

type Props = {
  children?: ReactNode;
  spacing?: spacingSize;
};

export function Stack({ children, spacing = "md" }: Props) {
  return (
    <div>
      {Children.map(children, (child, i) => {
        if (child !== null && child !== undefined) {
          return (
            <Item key={i} $spacingSize={spacing}>
              {child}
            </Item>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
}

export const Row = memo(function Row({
  title,
  children,
}: {
  children: ReactNode;
  title: string;
}) {
  return (
    <Fragment>
      <Title>{title}</Title>
      {children}
    </Fragment>
  );
});

const Title = styled(H1)`
  margin-bottom: 8px;
`;

export const Pair = styled.div`
  display: flex;
  width: 754px;
  margin-top: 12px;
  margin-bottom: 16px;
`;

export const Header = styled.div`
  font-size: ${Font.fontSizeMd};
  line-height: calc(${Font.fontSizeMd} * 1.25);
  color: ${Colors.black56};
  font-family: ${Font.fontFamilyDefault};
  font-style: normal;
  text-align: justify;
  width: 208px;
  flex-shrink: 0;
`;

export const Value = styled.div`
  font-size: ${Font.fontSizeSm};
  line-height: calc(${Font.fontSizeSm} * 1.25);
  color: ${Colors.black56};
  font-family: ${Font.fontFamilyDefault};
  font-style: normal;
  text-align: justify;
`;

const ItemStyle = css`
  &:first-child {
    padding-top: 0;
  }
`;

const Item = styled.div<{ $spacingSize: spacingSize }>`
  ${ItemStyle}

  ${({ $spacingSize }) => {
    switch ($spacingSize) {
      case "sm":
        return `padding-top: ${spacing.sm};`;
      case "md":
        return `padding-top: ${spacing.md};`;
      case "lg":
        return `padding-top: ${spacing.lg};`;
      case "xl":
        return `padding-top: ${spacing.xl};`;
      case "xxl":
        return `padding-top: ${spacing.xxl};`;
    }
  }}
`;
